import './Followup.scss';
import '../../../../theme/fonts.scss';

import dayjs from 'dayjs';
import { FunctionComponent, JSX, useEffect, useState } from 'react';

import { IS_APP_QOLIBRI } from '../../../../application';
import { ArrowLeft, ArrowRight, IconButton } from '../../../../components';
import { usePatientBarometerAnswers } from '../../../../services/api/barometer-answers';
import { COLORS } from '../../../../theme/colors';
import { BarometerType } from '../../../../types/barometer';
import { capitalize } from '../../../../utils';
import { usePatientDetailsOutletContext } from '../../outlet';
import { Activities } from './components/Activities/Activities';
import { Barometer } from './components/Barometer/Barometer';
import { BAROMETER_TYPES } from './components/Barometer/constants';
import { ComplementaryTreatments } from './components/ComplementaryTreatments/ComplementaryTreatments';
import { MedicineTreatments } from './components/MedicineTreatments/MedicineTreatments';
import { FourTimeMarkers } from './types';

const TIME_MARKER_LINE_VERTICAL_OFFSET_IN_PX = 136;
const TIME_MARKER_LINE_VERTICAL_TOP_OFFSET_IN_PX = 48;

export const Followup: FunctionComponent = () => {
  const { patient } = usePatientDetailsOutletContext();

  const [shownBarometerTypes, setShownBarometerTypes] = useState<
    Record<BarometerType, boolean>
  >(
    BAROMETER_TYPES.reduce<Record<BarometerType, boolean>>(
      (acc, type) => ({ ...acc, [type]: true }),
      {} as Record<BarometerType, boolean>,
    ),
  );

  const today = dayjs().startOf('day');
  const [timeMarkers, setTimeMarkers] = useState<FourTimeMarkers>([
    today.subtract(3, 'month'),
    today.subtract(2, 'month'),
    today.subtract(1, 'month'),
    today,
  ]);

  const {
    isLoading: isLoadingBarometerAnswers,
    data: groupedBarometerAnswers,
    isError: isErrorForBarometerAnswers,
  } = usePatientBarometerAnswers(patient.id ?? '');

  const [isBackendError, setIsBackendError] = useState<boolean>(false);

  const [bodyHeight, setBodyHeight] = useState<number>(
    document.body.offsetHeight,
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setBodyHeight(entries[0].target.clientHeight);
    });

    resizeObserver.observe(document.body);

    return () => resizeObserver.disconnect();
  }, []);

  const timeMarkerComponents: JSX.Element[] = [];
  const timeMarkerLines: JSX.Element[] = [];
  timeMarkers.forEach((date, index) => {
    const jsDate = date.toDate();
    const month = capitalize(
      jsDate.toLocaleDateString('fr-FR', { month: 'long' }),
    );
    const day = jsDate.toLocaleDateString('fr-FR', { day: '2-digit' });
    const isToday = date.isSame(today);

    const timeMarkerTextClass = isToday ? 'time-marker-text-today' : '';

    timeMarkerComponents.push(
      <div
        className={`time-marker time-marker-${index}`}
        key={date.toISOString()}
      >
        <p className={`time-marker-text-month ${timeMarkerTextClass}`}>
          {month}
        </p>
        <p className={`time-marker-text-day ${timeMarkerTextClass}`}>{day}</p>
      </div>,
    );
    timeMarkerLines.push(
      <div
        className={`time-marker-line-container time-marker-line-container-${index}`}
        style={{
          height:
            (bodyHeight - TIME_MARKER_LINE_VERTICAL_OFFSET_IN_PX) * 0.8415,
          top: TIME_MARKER_LINE_VERTICAL_TOP_OFFSET_IN_PX,
        }}
        key={date.toISOString()}
      >
        {isToday && <div className="time-marker-circle-today" />}
        <div
          className={`time-marker-line ${
            isToday ? 'time-marker-line-today' : ''
          }`}
        />
      </div>,
    );
  });

  const onClickLeftArrow = () =>
    setTimeMarkers(
      timeMarkers.map((date) => date.subtract(1, 'month')) as FourTimeMarkers,
    );

  const onClickRightArrow = () =>
    setTimeMarkers(
      timeMarkers.map((date) => date.add(1, 'month')) as FourTimeMarkers,
    );

  const isRightArrowHidden = timeMarkers[timeMarkers.length - 1].isSame(today);
  const patientCreationDate = dayjs(patient.createdAt);
  const isLeftArrowHidden =
    patientCreationDate === null || patientCreationDate.isAfter(timeMarkers[0]);

  const leftArrowButton = (
    <IconButton
      Icon={ArrowLeft}
      color={COLORS.texts.second}
      onClick={onClickLeftArrow}
      style={{ visibility: isLeftArrowHidden ? 'hidden' : 'visible' }}
      data-testid="left-arrow-button"
    />
  );

  const rightArrowButton = (
    <IconButton
      Icon={ArrowRight}
      color={COLORS.texts.second}
      onClick={onClickRightArrow}
      style={{ visibility: isRightArrowHidden ? 'hidden' : 'visible' }}
      data-testid="right-arrow-button"
    />
  );

  const activitiesDone = (
    <Activities patientId={patient.id ?? ''} timeMarkers={timeMarkers} />
  );

  const medicineTreatments = (
    <MedicineTreatments
      patientId={patient.id ?? ''}
      timeMarkers={timeMarkers}
      isBackendError={isBackendError}
      setIsBackendError={setIsBackendError}
    />
  );

  const complementaryTreatments = (
    <ComplementaryTreatments
      patientId={patient.id ?? ''}
      timeMarkers={timeMarkers}
      isBackendError={isBackendError}
      setIsBackendError={setIsBackendError}
    />
  );

  const containers = IS_APP_QOLIBRI
    ? [activitiesDone, medicineTreatments, complementaryTreatments]
    : [medicineTreatments, complementaryTreatments, activitiesDone];

  return (
    <div className="followup-container">
      <div className="time-marker-lines-container">{timeMarkerLines}</div>
      <div className="time-navigation-bar">
        {leftArrowButton}
        {rightArrowButton}
        <div className="time-markers-container">{timeMarkerComponents}</div>
      </div>
      <Barometer
        isError={isErrorForBarometerAnswers}
        isLoading={isLoadingBarometerAnswers}
        groupedBarometerAnswers={groupedBarometerAnswers ?? []}
        timeMarkers={timeMarkers}
        shownBarometerTypes={shownBarometerTypes}
        setShownBarometerTypes={setShownBarometerTypes}
      />
      <div className="followup-treatments-container">{containers}</div>
    </div>
  );
};
