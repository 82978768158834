import './Footer.scss';

import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { IS_APP_QOLIBRI } from '../../application';
import { ApplicationLogo } from '../../molecules/ApplicationLogo/ApplicationLogo';
import { ExternalLink } from '../ExternalLink/ExternalLink';

export const FOOTER_CONTACT_EMAIL_TEST_ID = 'footer-contact-link';
export const FOOTER_LEGAL_NOTICE_TEST_ID = 'footer-legal-notice-link';
export const FOOTER_TERMS_OF_USE_TEST_ID = 'footer-terms-of-use-link';
export const FOOTER_PRIVACY_POLICY_TEST_ID = 'footer-privacy-policy-link';
export const FOOTER_COPYRIGHT_TEST_ID = 'footer-copyright-link';

export const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="footer footer-col">
      <div className="footer-center footer-logo">
        <ApplicationLogo />
      </div>

      <div className="footer-center footer-row">
        <ExternalLink
          className="footer-big-link"
          text={t('generalInformation.contact')}
          href={`mailto:${import.meta.env.VITE_CONTACT_EMAIL}`}
          data-testid={FOOTER_CONTACT_EMAIL_TEST_ID}
        />
        <ExternalLink
          className="footer-big-link"
          text={t('generalInformation.legalNotice')}
          href={import.meta.env.VITE_LEGAL_NOTICE_LINK}
          data-testid={FOOTER_LEGAL_NOTICE_TEST_ID}
        />
        <ExternalLink
          className="footer-big-link"
          text={t('generalInformation.termsOfUse')}
          href={import.meta.env.VITE_TERMS_OF_USE_LINK}
          data-testid={FOOTER_TERMS_OF_USE_TEST_ID}
        />
        <ExternalLink
          className="footer-big-link"
          text={t('generalInformation.privacyPolicy')}
          href={import.meta.env.VITE_PRIVACY_POLICY_LINK}
          data-testid={FOOTER_PRIVACY_POLICY_TEST_ID}
        />
      </div>

      <div className="footer-center">
        <a
          className="footer-small-link"
          href={import.meta.env.VITE_COPYRIGHT_LINK}
          target="_blank"
          rel="noreferrer"
          data-testid={FOOTER_COPYRIGHT_TEST_ID}
        >
          <p>
            {t(
              `generalInformation.copyright.${IS_APP_QOLIBRI ? 'qolibri' : 'edol'}`,
              { year: new Date().getFullYear() },
            )}
          </p>
        </a>
      </div>
    </div>
  );
};
