import { FunctionComponent } from 'react';

import { IS_APP_QOLIBRI } from '../../application';
import edolLogo from '../../assets/Logo_eDOL_V3.png';
import qoLibriLogo from '../../assets/Logo_QoLibri.png';

export const APPLICATION_LOGO_TEST_ID = 'application-logo-test-id';

const QOLIBRI_DIM = {
  src: qoLibriLogo,
  width: 160,
  height: 45,
};
const EDOL_DIM = {
  src: edolLogo,
  width: 115,
  height: 45,
};

export const ApplicationLogo: FunctionComponent = () => {
  const props = IS_APP_QOLIBRI ? QOLIBRI_DIM : EDOL_DIM;

  return (
    <img
      {...props}
      alt="application logo"
      data-testid={APPLICATION_LOGO_TEST_ID}
    />
  );
};
