import './Login.scss';
import '../../theme/shadows.scss';

import { AppShell } from '@mantine/core';
import { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IS_APP_QOLIBRI } from '../../application';
import { Button, ButtonClass, SnackBar, SnackBarType } from '../../components';
import { Footer } from '../../components/Footer/Footer';
import { FastEmail } from '../../components/icons/FastEmail';
import { ApplicationLogo } from '../../molecules/ApplicationLogo/ApplicationLogo';
import { useSignIn } from '../../services/auth/useSignIn';
import { COLORS } from '../../theme/colors';
import { EMAIL_REGEX } from '../../utils';
import { EmailTextInput } from './components/EmailTextInput/EmailTextInput';
import { PasswordTextInput } from './components/PasswordTextInput/PasswordTextInput';

export const Login: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<{ email: string; password: string }>({ mode: 'onBlur' });

  const { signIn, signInError, setSignInError } = useSignIn();

  const contactEmail = IS_APP_QOLIBRI
    ? import.meta.env.VITE_QOLIBRI_CONTACT_EMAIL
    : import.meta.env.VITE_EDOL_CONTACT_EMAIL;

  return (
    <AppShell padding={0}>
      <div className="login-container" data-testid="login">
        <div className="login shadow-strong">
          <div className="login-presentation-container">
            <div className="login-title-container">
              <h1 className="h1">{t('login.title')}</h1>
              <ApplicationLogo />
            </div>
            <div className="login-subtitle-container">
              <p className="p1 login-subtitle-text">
                {t(`login.${IS_APP_QOLIBRI ? 'qolibri' : 'edol'}.subtitle`)}
              </p>
            </div>
          </div>
          <div className="login-content-container">
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <EmailTextInput
                    inputTestId={'email-text-input'}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    isValid={errors.email === undefined}
                    errorMessage={t('login.errorMessage.invalidEmailAddress')}
                    label={t('login.emailAddress')}
                  />
                );
              }}
              rules={{
                required: true,
                validate: {
                  isValidEmail: (value) => EMAIL_REGEX.test(value),
                },
              }}
            />

            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <PasswordTextInput
                    inputTestId={'password-text-input'}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    isValid={errors.password === undefined}
                  />
                );
              }}
              rules={{ required: true }}
            />

            <div className="login-container-right">
              <a
                className="login-subtext login-link"
                onClick={() => navigate('/reset-password')}
              >
                {t('login.forgottenPassword')}
              </a>
            </div>

            <div className="login-connection-and-contact-container">
              <Button
                buttonClass={ButtonClass.PRIMARY}
                className="connection-button"
                text={t('login.connection')}
                onClick={() => {
                  setSignInError(null);
                  handleSubmit(signIn)();
                }}
                disabled={!isValid}
                data-testid="submit-button"
              />
              <div className="login-contact-container">
                <p className="login-text">{t('login.cannotConnect')}</p>
                <a
                  className="login-text login-link"
                  href={`mailto:${contactEmail}`}
                >
                  {t('login.contact')}
                </a>
                <FastEmail color={COLORS.primary._500} />
              </div>
            </div>
            {signInError !== null && (
              <SnackBar
                title={signInError.message}
                snackBarType={SnackBarType.ERROR}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </AppShell>
  );
};
