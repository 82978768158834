import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLink } from '../../components';
import { QuestionCircle } from '../../components/icons/QuestionCircle';

export const LEGAL_LINKS_CONTACT_EMAIL_TEST_ID = 'patient-list-contact-link';

export const LegalLinks: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <ExternalLink
        Icon={QuestionCircle}
        text={t('generalInformation.contact')}
        href={`mailto:${import.meta.env.VITE_CONTACT_EMAIL}`}
        data-testid={LEGAL_LINKS_CONTACT_EMAIL_TEST_ID}
      />
    </>
  );
};
