import './services/date/dayjs';
import './index.scss';

import { MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { CURRENT_APP_VERSION, IS_APP_QOLIBRI } from './application';
import { ActivityIndicator } from './components';
import { useAppResources } from './hooks/useAppResources';
import { useAuthentication } from './hooks/useAuthentication';
import { authenticatedRouter, unauthenticatedRouter } from './routes/router';
import { MANTINE_COLORS } from './theme/colors';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [import.meta.env.VITE_API_URL],
  environment: import.meta.env.VITE_APPLICATION_ENV,
  maxValueLength: 512,
  release: CURRENT_APP_VERSION,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

export const App: FunctionComponent = () => {
  const areAppResourcesLoaded = useAppResources();
  const { user } = useAuthentication();

  useEffect(() => {
    document.title = t(
      `application.tabName.${IS_APP_QOLIBRI ? 'qolibri' : 'edol'}`,
    );
  }, []);

  return areAppResourcesLoaded ? (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          fontFamily: "'Be Vietnam Pro', sans-serif",
          colors: MANTINE_COLORS,
        }}
      >
        <DatesProvider settings={{ locale: 'fr' }}>
          <RouterProvider
            router={user === null ? unauthenticatedRouter : authenticatedRouter}
          />
        </DatesProvider>
      </MantineProvider>
    </QueryClientProvider>
  ) : (
    <ActivityIndicator />
  );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
